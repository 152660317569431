import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import useTransactionsImporter from 'hooks/transactions/useTransactionsImport.js'
import Button from 'design/Button'
import { useTranslation, Trans } from 'react-i18next'
import { LIST_BANK_CONNECTIONS_WITH_ACCOUNTS } from 'graphql/bank-connections.js'
import { useQuery } from '@apollo/client'
import { Select, Divider, Tooltip } from 'antd'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

ImportContent.propTypes = {
  refetchTransactions: PropTypes.func,
  closePanel: PropTypes.func
}

export default function ImportContent ({ refetchTransactions, closePanel }) {
  const { t } = useTranslation()
  const { data } = useQuery(LIST_BANK_CONNECTIONS_WITH_ACCOUNTS, { fetchPolicy: 'cache-only' })
  const banksConnections = data?.listBankConnectionsWithAccounts || []
  const [accountId, setAccountId] = useState(null)

  const { triggerImport } = useTransactionsImporter({
    onImportComplete: () => {
      refetchTransactions()
      closePanel()
    },
    accountId
  }, [accountId, refetchTransactions, closePanel])
  const onChange = useCallback((accountId) => {
    setAccountId(accountId)
  }, [setAccountId])

  const onImportButtonClicked = useCallback(() => {
    triggerImport({ accountId })
  }, [triggerImport, accountId])

  return (
    <>
      <div className='w-full flex flex-col items-center'>
        <div className='w-full items-left'>
          {t('component.importTransactions.info1')}
        </div>
        <Divider className='mb-4 mt-4' />
        <div className='w-full items-left'>
          <div className='mb-3'>{t('component.importTransactions.infoColumnIntro')} :</div>

          <div>
            <Tooltip
              placement='top'
              title={(
                <>
                  <div className='mb-2'>{t('component.importTransactions.infoColumn1.tooltip1')}</div>
                  <div className='mb-2'>{t('component.importTransactions.infoColumn1.tooltip2')}</div>
                  <div className='mb-2'>{t('component.importTransactions.infoColumn1.tooltip3')}</div>
                </>)}
              className='flex flex-row items-center space-x-1 max-w-max'
            >
              <Trans
                i18nKey='component.importTransactions.infoColumn1'
                components={{
                  Bold: <span className='font-bold' />
                }}
              />
              <InformationCircleIcon className='w-4 h-4 text-red-300 mr-1' />
            </Tooltip>
          </div>
          <div>
            <Trans
              i18nKey='component.importTransactions.infoColumn2'
              components={{
                Bold: <span className='font-bold' />
              }}
            />
          </div>
          <div>
            <Tooltip
              placement='top'
              title={(
                <>
                  <div className='mb-2'>{t('component.importTransactions.infoColumn3.tooltip1')}</div>
                  <div className='mb-2'>{t('component.importTransactions.infoColumn3.tooltip2')}</div>
                  <div className='mb-2'>{t('component.importTransactions.infoColumn3.tooltip3')}</div>
                  <div className='mb-2'>{t('component.importTransactions.infoColumn3.tooltip4')}</div>
                </>)}
              className='flex flex-row items-center space-x-1 max-w-max'
            >
              <Trans
                i18nKey='component.importTransactions.infoColumn3'
                components={{
                  Bold: <span className='font-bold' />
                }}
              />

              <InformationCircleIcon className='w-4 h-4 text-red-300 mr-1' />
            </Tooltip>
          </div>
          <div>
            <Trans
              i18nKey='component.importTransactions.infoColumn4'
              components={{
                Bold: <span className='font-bold' />
              }}
            />
          </div>
          <div>
            <Trans
              i18nKey='component.importTransactions.infoColumn5'
              components={{
                Bold: <span className='font-bold' />
              }}
            />
          </div>
          <div className='mb-3 mt-5'>{t('component.importTransactions.infoColumnIntro2')}</div>
        </div>
        <Divider className='mb-4 mt-4' />
        <div className='w-full items-left'>
          <div className='mb-1'>
            {t('component.importTransactions.labelComboboxForm')} :
          </div>
          <Select
            className='w-[400px]'
            onChange={onChange}
            options={banksConnections.map((banksConnection) => ({
              label: (<span>{banksConnection.bankName}</span>),
              title: 'manager',
              options: banksConnection.accounts.map((account) => ({
                label: (<span>{account.customName || account.originalName}</span>),
                value: account.id
              }))
            }))}
          />
        </div>
        <Button
          disabled={accountId === null}
          label={t('component.invoiceConnectorList.importAFile')}
          className='self-end'
          onClick={onImportButtonClicked}
          primary
        />
      </div>
    </>
  )
}
